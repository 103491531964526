import '@builder.io/widgets';
import BuilderResource from './components/BuilderResource';
import { builder } from '@builder.io/react';
import { render } from 'react-dom';

const builderToReactComponentMap = {
  'A Weekend For Water Pledge Form': 'NashvillePledgeForm',
  'Brand Partnerships Form': 'BrandPartnershipsForm',
  'Campaign Search': 'Campaign/Search/WrappedCampaignSearch',
  'Campaign Template Leaderboard': 'CampaignTemplateLeaderboard',
  'Completed Projects Map': 'WrappedCompletedProjectsMap',
  'Donate Form': 'DonateForm/WrappedDonateForm',
  'EOYG 2024 Banner': 'Eoyg2024Banner',
  'Lead Form': 'WrappedLeadForm',
  'Sticky Banner': 'StickyBanner',
  'Take Action': 'TakeAction',
  'VideoAsk Widget': 'VideoAskWidget',
  'Spring Map': 'WrappedSpringMap',
  'WWD 2024 Quiz': 'WwdQuiz',
  'WWD Impact Slider': 'WwdImpactSlider',
};

const modules = import.meta.glob('~/javascripts/components/**/*.jsx');

function initBuilder() {
  if (!window.BuilderApiKey) return;

  builder.init(window.BuilderApiKey);
}

function hydrateBuilderResources() {
  document.querySelectorAll('[data-builder-resource]').forEach((el) => {
    const content = JSON.parse(el.dataset.content);

    const { locale, builderResource } = el.dataset;

    const theme = JSON.parse(el.dataset.theme);

    const reactComponentsToImport = window.BuilderPreview
      ? Object.values(builderToReactComponentMap)
      : Object.keys(content.meta.componentsUsed || {}).map(
          (builderComponent) => builderToReactComponentMap[builderComponent]
        );

    const promises = reactComponentsToImport.map((component) =>
      modules[`/javascripts/components/${component}.jsx`]()
    );

    Promise.all(promises).then(() => {
      render(
        <BuilderResource
          content={content}
          customComponentProps={JSON.parse(el.dataset.componentProps)}
          locale={locale}
          resourceName={builderResource}
          theme={theme}
        />,
        el.querySelector('[data-name]')
      );
    });
  });
}

document.addEventListener('DOMContentLoaded', initBuilder);
document.addEventListener('DOMContentLoaded', hydrateBuilderResources);
document.addEventListener('ModalOpened', hydrateBuilderResources);
